import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp, apigetUserDetails } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { setHelpModeActive } from 'store/theme/themeSlice'

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()
	let { token, signedIn } = useSelector((state) => state.auth.session)


	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values)

			if (resp.data && resp.data.status !== "failed" ||resp.data.status !== "Failed") {

				debugger
				const { token } = resp.data
				let dataSignIn = {
					token: token,
					sighedIn: false
				}
				dispatch(onSignInSuccess(dataSignIn))
				localStorage.setItem('userType', resp.data.userType)
				localStorage.setItem('access_token', token)
				if (resp.data.userType) {
					dispatch(setUser(resp.data.userType || {
						avatar: '',
						userName: 'Anonymous',
						authority: ['USER'],
						email: ''
					}))
				}
				if (resp.status === 200  ||resp.data.status !== "failed" &&
				resp.data.status !== "Failed") {
					setTimeout(() => {
						let dataSignIn = {
							token: token,
							sighedIn: true
						}
						dispatch(onSignInSuccess(dataSignIn))
						const getUserData = async () => {
							try {
								const response = await apigetUserDetails(resp.data.userId)

								if (response.data && response.status == 200) {
									dispatch(setHelpModeActive(false))

									localStorage.setItem('userDetails', JSON.stringify(response.data.userDetail))
									navigate(resp.data.userType === "Customer" ?
										appConfig.companyEntryPath : resp.data.userType === "Company" ?
											response.data.userDetail.customerTypes.customerTypes === 'Company' ?
												appConfig.companyEntryPath : appConfig.customerEntryPath : appConfig.authenticatedEntryPath)
									// return {
									// 	status: 'success',
									// 	message: '',
									// 	resp: resp
									// }
									 // Reload window after successful sign-in
									 window.location.reload();
								}
							} catch (errors) {
								return {
									status: 'failed',
									ssage: errors?.response?.data?.message || errors.toString(),
									errors: errors?.response?.data?.status
								}
							}
						}
						getUserData()
					}, 1300)
				}else{
					signOut();
					navigate("/sign-in");
				}
				// appConfig.authenticatedEntryPath
				//const redirectUrl = query.get(REDIRECT_URL_KEY)


			}
			else{
				signOut();
				navigate("/sign-in");
			}
		} catch (error) {
			if (error?.response?.status !== 200) {
			  signOut();
			  navigate("/sign-in");
			}
		
			return {
			  status: "failed",
			  message: error?.response?.data?.message || error.toString(),
			};
		  }
	}



	const signUp = async (values) => {
		debugger
		try {

			const resp = await apiSignUp(values)

			if (resp.data) {
				// const { token } = resp.data
				// dispatch(onSignInSuccess(token))
				// if (resp.data.user) {
				// 	dispatch(setUser(resp.data.user || {
				// 		avatar: '',
				// 		userName: 'Anonymous',
				// 		authority: ['USER'],
				// 		email: ''
				// 	}))
				// }
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			} else {
				return {
					status: 'failed',
					message: resp?.message
				}
			}
		} catch (errors) {
			debugger
			return {
				status: 'failed',
				message: errors?.response?.data?.message || 'Something went wrong, Please try again letter.'
			}
		}
	}

	const handleSignOut = () => {
		localStorage.removeItem('userType')
		localStorage.removeItem('access_token')
		localStorage.removeItem('userDetails')
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		// await apiSignOut()
		handleSignOut()
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut
	}
}

export default useAuth
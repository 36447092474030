const appConfig = {
    // apiPrefix: 'https://api.credence.zontecsolutions.com/api/Credence/',
    // apiPrefix: 'http://local.credenceapi.com/api/Credence/',
    apiPrefix: 'https://credenceapi.complianceguru.co.in/api/Credence/',
    apFilePrefix: 'https://credenceapi.complianceguru.co.in/',
    authenticatedEntryPath: '/dashboard',
    customerEntryPath: '/home',
    companyEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig
import ApiService from './ApiService'
// import * from '../../'
const API_URL=process.env.BACKEND_API

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: `login`,
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
   
    return ApiService.fetchData({
        url: 'register',
        method: 'post',
        mode:'no-cors',
        data
    })
}
export async function apigetUserDetails (id) {
   
    return ApiService.fetchData({
        url: `GetUserDetails?Id=${id}`,
        method: 'get',
        mode:'no-cors',
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: 'logout',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: 'ForgotPassword',
        method: 'post',
        data
    })
}

export async function activeAccount (token) {
    return ApiService.fetchData({
        url: `ActivateAccount?token=${token}`,
        method: 'post',
    })
}

export async function apiResetPassword (data) {
   
    return ApiService.fetchData({
        url: 'ResetPassword',
        method: 'post',
        data
    })
}
export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: 'UpdatePassword',
        method: 'post',
        data
    })
}

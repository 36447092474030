import BaseService from './BaseService'
import { toast, Notification } from 'components/ui'

// const ApiService = {
//     fetchData(param) {
//         return new Promise((resolve, reject) => {
//             BaseService(param).then(response => {
//                 // 
//                 // let a=response.headers.get('x-pagination')
//                 resolve(response)
//                 debugger
//                 if(response.data?.message){
//                     toast.push(
//                         <Notification title={'Successfuly Done'} type="success" duration={2500}>
//                             {response.data.message}
//                         </Notification>
//                         ,{
//                             placement: 'top-center'
//                         }
//                     )
//                 }
//             }).catch(errors => {
//                 reject(errors)
//             })
//         })
//     }
// }
const ApiService = {
    fetchData(param) {
      return new Promise((resolve, reject) => {
        BaseService(param)
          .then((response) => {
            debugger;
            resolve(response);
            if (response?.data?.message  || response?.data?.response) {
              toast.push(
                <Notification
                  title={"Successfuly Done"}
                  type="success"
                  duration={2500}
                >
                    {response.data.message || response?.data?.response}
                </Notification>,
                {
                  placement: "top-center",
                }
              );
            }
          })
          .catch((errors) => {
            debugger;
  
            if (errors.response.data?.message || errors.response.data?.response  ) {
              toast.push(
                <Notification title={"Failed"} type="danger" duration={2500}>
                  {errors.response.data?.message || errors.response.data?.response  }
                </Notification>,
                {
                  placement: "top-center",
                }
              );
            }
            //reject(errors)
          });
      });
    },
  };
  
export default ApiService
import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, ORIGIN } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { setUser, initialState } from '../store/auth/userSlice'
import { useNavigate } from 'react-router-dom'
import useAuth from 'utils/hooks/useAuth'

const unauthorizedCode = [401]

const BaseService = axios.create({
    mode: 'no-cors',
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
    headers: {
        "Access-Control-Allow-Headers": "origin, content-type, accept",
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS'
    }
})

BaseService.interceptors.request.use(config => {
    
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    const accessToken = persistData.auth.session.token
    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }
    config.headers[ORIGIN] = `*`
    return config
}, error => {
    return Promise.reject(error)
})



BaseService.interceptors.response.use(
    response => response,
    error => {
        // const { signOut } = useAuth()
        
        const { response } = error
        if (response && unauthorizedCode.includes(response.status)) {
            localStorage.removeItem('userType')
            localStorage.removeItem('access_token')
            localStorage.removeItem('userDetails')
            store.dispatch(setUser(initialState))
            // signOut()
            store.dispatch(onSignOutSuccess())
            
        }
        return Promise.reject(error)
    }
)

export default BaseService
import React, { memo, useMemo, lazy, Suspense, useState, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { setHelpModeActive } from 'store/theme/themeSlice'

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)
	const dispatch = useDispatch()
	const { authenticated } = useAuth()
	useDirection()

	useLocale()
	
	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])
	const panelExpand = useSelector((state) => state?.theme?.panelExpand)
	const userType = localStorage.getItem('userType')
	let userDetails = {}
	if (localStorage.getItem('userDetails')) {
		userDetails = JSON.parse(localStorage.getItem('userDetails'))
	}

	const [helpSetting, setHelpSetting] = useState({
		run: false,
		steps: [
		],
	});

	const { steps, run } = helpSetting

	useEffect(() => {
		setHelpSetting({ ...helpSetting, run: panelExpand })
	}, [panelExpand])

	useEffect(() => {
		if (userType !== undefined) {
			if (userType == 'Administrator') {
				setHelpSetting({
					...helpSetting, steps: [
						{
							content: <><h3 className='mb-2'>Let's begin our journey!</h3>
								<div className='d-flex align-self-center gap-2 align-items-baseline'>
								<h6>Leading India's Online Business Experts</h6>
									<h6>Start, Grow, Sustain with Pro Tax & Startup Services.</h6></div>
							</>,
							locale: { next: <span aria-label="skip">Start</span> },
							placement: 'center',
							target: 'body',

						},
						{
							content: <p className='joyrid-content-comon'>Easily manage and upload templates in Credence's streamlined menu for efficient business solutions.</p>,
							floaterProps: {
								disableAnimation: true,
							},
							target: '.templateMenu',
							title: 'Template Menu',
						},
						{
							content: <p className='joyrid-content-comon'>Seamlessly allocate roles via the Manage Roles.</p>,

							floaterProps: {
								disableAnimation: true,
							},
							target: '#ManageRoles',
							title: 'Manage Roles',
						},
						{
							content: <p className='joyrid-content-comon'>Efficiently oversee customer interactions and data. </p>,

							placement: 'top',
							target: '#ManageCustomers',
							title: 'Manage Customers',
						},
						{
							content: <p className='joyrid-content-comon'>Simplify operations through efficient user management.</p>,

							placement: 'top',
							target: '#ManageUsers',
							title: 'Manage Users',
						},
						{
							content: <p className='joyrid-content-comon'> Sint voluptate tempor exercitation officia.</p>,

							placement: 'top',
							target: '#individualDashboard',
							locale: { last: 'End' },
							title: 'Dashboard',
						},
					]
				})
			} else if (userType == 'Customer') {
				if (userDetails?.customerTypes?.customerTypes == "Individual") {
					setHelpSetting({
						...helpSetting, steps: [
							{
								content:<><h3 className='mb-2'>Let's begin our journey!</h3>
								<div className='d-flex align-self-center gap-2 align-items-baseline'>
								<h6>Leading India's Online Business Experts</h6>
									<h6>Start, Grow, Sustain with Pro Tax & Startup Services.</h6></div>
							</>,
								locale: { next: <span aria-label="skip">Start</span> },
								placement: 'center',
								target: 'body',
							},
							{
								content: <p className='joyrid-content-comon'>Personalized account services for individuals.</p>,
								target: '#customer_profile',
								title: 'customer_profile',
							},
							{
								content: <p className='joyrid-content-comon'>User-centric personal dashboards for insights.</p>,

								target: '#individualDashboard',
								title: 'customer_profile',
							},
							{
								content: <p className='joyrid-content-comon'> View detailed invoice transaction data in sales data.</p>,

								target: '#ManageReport',
								locale: { last: 'End' },
								title: 'Manage Report',
							}
						]
					})
				} else {
					setHelpSetting({
						...helpSetting, steps: [
							{
								content: <><h3 className='mb-2'>Let's begin our journey!</h3>
								<div className='d-flex align-self-center gap-2 align-items-baseline'>
								<h6>Leading India's Online Business Experts</h6>
									<h6>Start, Grow, Sustain with Pro Tax & Startup Services.</h6></div>
							</>,
								locale: { next: <span aria-label="skip">Start</span> },
								placement: 'center',
								target: 'body',

							},
							{
								content: <p className='joyrid-content-comon'>Dedicated account services for business users</p>,

								target: '#customer_profile',
								title: 'Account Section',
							},
							{
								content: <p className='joyrid-content-comon'>User-centric personal dashboards for insights. </p>,

								target: '#individualDashboard',
								title: 'Dashboard',
							},

							{
								content: <p className='joyrid-content-comon'>Simplify company management for business users.</p>,

								target: '#ManageCompany',
								title: 'Manage Company',
							},
							{
								content: <p className='joyrid-content-comon'>Comprehensive sales data for business users.</p>,

								target: '#ManageReport',
								locale: { last: 'End' },
								title: 'Sales Data',
							},
							
						]
					})
				}

			}
		}
	}, [userType,userDetails?.customerTypes?.customerTypes])

	const handleJoyrideCallback = (data) => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setHelpSetting({ ...helpSetting, run: false });
			dispatch(setHelpModeActive(false))
		}

		// logGroup(type, data);
	};  
	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<Joyride
				callback={handleJoyrideCallback}
				continuous
				hideCloseButton
				run={run}
				scrollToFirstStep
				className="joyrid-content-comon"
				// showProgress
				showSkipButton
				steps={steps}
				disableOverlayClose
				styles={{
					options: {
						zIndex: 10000,
					},
				}}
			/>
			<AppLayout />
		</Suspense>
	)
}

export default memo(Layout)